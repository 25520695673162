import React from "react";
import ReviewsList from "./lists/ReviewsList";


const Reviews = () => {
  return (
    <>
      <ReviewsList />
    </>
  );
};

export default Reviews;
