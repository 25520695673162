import React from 'react'
import MailList from './lists/MailList'

const MailingList = () => {
	return (
		<>
			<MailList />
		</>
	)
}

export default MailingList