import React from 'react'
import ContactList from './lists/ContactList'

const Contact = () => {
	return (
		<>
			<ContactList />
		</>
	)
}

export default Contact