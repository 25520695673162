import React from "react";

import OrderList from "./lists/OrderList";

const Orders = () => {
  return (
    <>
      <OrderList />
    </>
  );
};

export default Orders;
