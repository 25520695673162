import React from "react";
import UsersList from "./lists/UsersList";

const Users = () => {
  return (
    <>
      <UsersList />
    </>
  );
};

export default Users;
