import React from 'react'
import InvoicesList from './lists/InvoicesList'

const Invoices = () => {
	return (
		<>
			<InvoicesList />
		</>
	)
}

export default Invoices